import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Chi siamo"
      keywords={[
        `pizzeria`,
        `castellanza`,
        `theitalianjob`,
        `pizzeria castellanza`,
      ]}
    />
    <h1>Chi siamo</h1>
    <p>
      Ciao! Io sono Daniele Polito, e insieme alla mia famiglia portiamo avanti
      questo progetto fatto con tanto amore e tanta passione
    </p>
    <p>
      <blockquote>
        --FRASE PER DIRE CHE SI USANO PRODOTTI DI QUALITA E CHE SI E' EFFICIENTI
      </blockquote>
      Da noi si privilegiano solo ingredienti di qualità selezionati da
      fornitori affidabili eccellenza nella nostra zona. Vuoi mettere poi
      l'efficienza? La puntualit&agrave; nostro marchio di fabbrica, troverai le
      tue pizze sempre cotte a puntino nel momento preciso in cui verrai a
      ritirarla.
    </p>
    <p>
      <blockquote>
        --FRASE PER DIRE CHE C'E' POSTO PER MANGIARE DENTRO
      </blockquote>
      E se poi magari sei di fretta, potrai mangiare qui, condividendo il
      momento con le persone che ami
    </p>
  </Layout>
)

export default IndexPage
